<template>
  <div class="restitution">
    <Spinner :is-loading="showSpinner" :message="$t('restitution.spinnerMessage')" />
    <div class="buttons">
      <div class="upload">
        <v-button @click="exportFile"><v-svg>export</v-svg>{{ $t('button.export') }}</v-button>
      </div>
    </div>
    <h1>{{ $t('restitution.title') }}</h1>
    <div class="filter">
      <div class="search">
        <div class="input-filter">
          <input
            v-model="filter"
            type="text"
            :placeholder="$t('restitution.filter.placeholder')"
            @keydown.enter="setSearch(filter)">
          <v-svg :class="{ purple: filter.length }">search</v-svg>
        </div>
        <div class="select-filter">
          <v-button v-for="(label, index) in search"
                    :key="index"
                    class="flat"
                    @click="removeSearch(index)">
            <v-svg>cancel</v-svg> {{ label }}
          </v-button>
        </div>
      </div>
      <div class="d-flex">
        <select
          v-if="habilitations.formatType.length === 0"
          v-model="selectedType"
          class="imputation-date-fiter"
          :title="$t('restitution.filter.typeFilterTitleAttr')"
          @change="fetchDatas($event)">
          <option v-for="option in typesList" :value="option.value">
            {{ option.viewValue }}
          </option>
        </select>
        <v-calendar :start-date="startDate" :end-date="endDate" @period-change="periodChange">
          <v-svg>calendar</v-svg>
          <template v-if="isDefaultPeriod || unsetFilterByInputDate"> {{ $t('entryDate') }} </template>
          <template v-else> {{ formatedPeriod }}</template>
        </v-calendar>
        <select
          v-model="selectedPeriod"
          class="imputation-date-fiter"
          :title="$t('restitution.filter.periodFilterTitleAttr')"
          @change="resetFilter('inputDate'); fetchDatas($event)">
          <option value="" selected disabled>Choisir une période comptable</option>
          <option v-for="option in imputationDateList" :value="option">
            Période de {{ FORMAT_DATE(option) }}
          </option>
        </select>
      </div>
    </div>
    <loading v-if="loading" />
    <GridEntries v-else :entries="slicedEntries()" :headers="headers" />
    <v-pagination
      :total-pages="totalPages"
      :total="filteredEntries.length"
      :per-page="rowCount"
      :current-page="currentPage"
      @update-per-page="updateRowCount"
      @pagechanged="onPageChange" />
  </div>
</template>

<script>
import { defaultStartDate, defaultEndDate } from '@/utils/CONF'
import GridEntries from '@/components/GridEntries'
import VPagination from '@/components/base/Pagination'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { formatDate, formatDateRest } from '@/utils/utils'
import ExcelExport from 'export-xlsx'
import { SETTINGS_FOR_EXPORT } from '../utils/setting_xlsx'
import i18n from "@/i18n"
import Spinner from '@/components/base/Spinner'


export default {
  name: 'Restitution',
  components: {
    Spinner,
    GridEntries,
    VPagination
  },
  data() {
    return {
      filter: '',
      search: [],
      imputationDateList: [],
      selectedPeriod: '',
      selectedType: 'ALL',
      showSpinner: false,
      loading: false,
      unsetFilterByInputDate: false,
      start: 0,
      rowCount: 10,
      currentPage: 1,
      headers: ['user', 'entryDate', 'imputationDate', 'format','accountingUnit', 'siteId', 'resultCenterId', 'family', 'amount', 'label', 'entryType'],
      typesList: [
        { value: 'ALL',              viewValue: 'Tout les types' },
        { value: 'HYPER',            viewValue: 'Hyper' },
        { value: 'SUPER',            viewValue: 'Super' },
        { value: 'INVENTAIRE_SUPER', viewValue: 'Inventaire' },
        { value: 'LOG',              viewValue: 'Logistique Hyper' },
      ],
    }
  },
  computed: {
      ...mapState('configuration', ['habilitations', 'validIndicators', 'startDate', 'endDate', 'closuresDates','formats']),
      ...mapGetters('entries', ['entries']),
      ...mapGetters('configuration', ['currentClosureDate']),
      filteredEntries() {
        let searchedEntries = []

        searchedEntries =
          !this.search.length
          ? this.entries.filter(entry => Object.values(entry))
          : this.entries.filter(entry => {
            let multipleSearchOk = true

            this.search.forEach(s => {
              if(!Object.values(entry).some(value => `${value}`.toUpperCase().includes(s.toUpperCase()))) multipleSearchOk = false
            })

            if (multipleSearchOk) return entry
          })

        searchedEntries = [...new Set(searchedEntries.flat())].sort((a, b) => b.date.seconds - a.date.seconds)
        return searchedEntries
      },
      isDefaultPeriod() {
        return this.startDate.hasSame(defaultStartDate, 'day') && this.endDate.hasSame(defaultEndDate, 'day')
      },
      formatedPeriod() {
        return this.startDate === this.endDate
          ? this.startDate.toFormat('dd/MM/yyyy')
          : `${this.startDate.toFormat('dd/MM/yyyy')} - ${this.endDate.toFormat('dd/MM/yyyy')}`
      },
      totalPages() {
        return Math.ceil(this.filteredEntries.length / this.rowCount)
      },
  },
  watch: {
    entries() {
      this.showSpinner = false
      this.loading = false
    }
  },
  created() {
    this.setImputationDateList()
    this.selectedPeriod = `${this.currentClosureDate?.year}${this.currentClosureDate?.month.toString().padStart(2, '0')}`
    this.fetchDatas(this.selectedPeriod)
  },
  methods: {
    ...mapMutations({ setPeriod: 'configuration/SET_PERIOD' }),
    ...mapMutations({ setEntries: 'entries/SET_ENTRIES_RESTIT' }),
    ...mapActions('entries', ['fetchEntriesRestitute']),

    async fetchDatas(dateValue = null) {
      this.loading = true
      try {
        let hab = {...this.habilitations}
        let searchParam = {
          imputationDate: this.selectedPeriod,
          site: hab.site,
          format: hab.formatType,
          types: this.formatFiltered(this.selectedType),
        }

        await this.fetchEntriesRestitute(searchParam)
      }catch(err) {
        this.loading = false
      }
    },
    setSearch(val) {
      this.loading = true
      this.filter = ''
      this.search.push(val)
      this.onPageChange(1)
      this.loading = false
    },
    removeSearch(index) {
      this.loading = true
      this.search.splice(index, 1)
      this.onPageChange(1)
      this.loading = false
    },
    slicedEntries() {
      return this.filteredEntries && this.filteredEntries.slice(this.start, this.start + this.rowCount)
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.start = (this.currentPage - 1) * this.rowCount
    },
    updateRowCount(value) {
      this.rowCount = parseInt(value)
      this.start = (this.currentPage - 1) * this.rowCount
    },
    async periodChange(event) {
      this.resetFilter('imputationPeriod')
      this.setPeriod({ startDate: event.start, endDate: event.end })
      this.fetchDatas()
    },
    async exportFile() {
      const excelExport = new ExcelExport()
      this.showSpinner = true
      const data = [ { data: this.filteredEntries.map(e => {
        let item = {...e, date: formatDateRest(e.date) }
        return item
      }) }]

      await new Promise(resolve => {
        resolve(excelExport.downloadExcel(SETTINGS_FOR_EXPORT, data))
      })
      this.showSpinner = false
    },
    FORMAT_DATE(date){
        return `${i18n.t("frenchMonths")[parseInt(date.substring(4, 6)) - 1]} ${date.substring(0, 4)}`
    },
    setImputationDateList() {
      this.imputationDateList = this.closuresDates.map(d => {
        let month = d.month.toString()
          return `${d.year}${month.padStart(2, '0')}`
      }).sort()
    },
    resetFilter(filter) {
      if( filter === 'inputDate' ) {
        this.unsetFilterByInputDate = true
      }else if(filter === 'imputationPeriod') {
        this.selectedPeriod = ''
      }
    },
    formatFiltered(type) {
      switch (type) {
        case 'HYPER' :
          return this.formats.filter( f => !f.includes('SUPER') && f !== 'ARL' && f !== 'ARA' && f != 'NegoceLog' )
          break
        case 'SUPER' :
          return this.formats.filter( f => f.includes(type) && f !== 'INVENTAIRE_SUPER')
          break
        case 'INVENTAIRE_SUPER' :
          return this.formats.filter( f => f === 'INVENTAIRE_SUPER')
          break
        case 'LOG' :
          return this.formats.filter( f => f === 'ARL' || f === 'ARA' || f === 'NegoceLog')
        default :
          return []
      }
    }
  }
}
</script>

<style lang="scss">
.restitution {
  overflow-y: scroll;
  position: relative;
  @media print {
    overflow: visible;
  }
  .buttons {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 6.4rem;
    right: 6.4rem;
    @media print {
      display: none;
    }
    .upload{
      margin-right: 10px;
      .disabledupload{
        background: $light-grey;
      }
      .enabledupload{
        background: $button-color;
        &:hover {
          background-color: $black;
        }
      }
      .label{
        display: flex;
        align-items: center;
        @extend %font-bold;
        border-radius: 1.6rem;
        padding: 1.6rem 3.2rem;
        font-size: 2rem;
        outline: none;
        transition: 200ms;
        box-shadow: 0px 3px 12px rgba($grey, 0.5);
        border: none;
        color: $white;
        cursor: pointer;
        .v-svg{
          margin-right: 0.4em;
          font-size: 0.8em;
        }
      }
      input[type='file'] {
        display: none;
      }
    }
  }
  .filter {
    margin-top: 2.4rem;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 2rem;

    .search {
      display: flex;
      flex-direction: column;
      .select-filter {
        display: flex;
        flex-direction: row;
        button{
          flex-direction: row-reverse;
          padding: 0.6em 1em 0.6em 1em;
          .v-svg {
            margin-left: 0.4em;
          }
        }
      }
    }

    @media print {
      display: none;
    }

    .input-filter {
      position: relative;
      input {
        height: 4.4rem;
        width: 100%;
        border-radius: 0.8rem;
        border: 1px solid $grey;
        caret-color: $purple;
        outline: none;
        padding-left: 3em;
        &::placeholder {
          color: $dark-grey;
        }
      }
    }
    .v-svg.svg-search {
      position: absolute;
      color: $dark-grey;
      font-size: 2em;
      left: 1rem;
      top: 0.6rem;
      &.purple {
        color: $purple;
      }
    }
  }

  .d-flex {
    display: flex;
    .imputation-date-fiter {
      width: 230px;
      margin-left: 15px;
      padding: 0 10px;
      border: none;
      background: transparent;
      height: 35px;
      &:focus-visible {
        outline: none;
      }
      &:active {
        background: $white !important;
        border: 1px solid $light-pirple !important;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom: none !important;
      }
      &:hover {
        background-color: $grey-transparent;
        border: 1px solid;
        border-color: $light-grey-border;
        border-bottom: none;
      }

    }
  }
}
</style>
