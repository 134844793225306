import { DateTime } from 'luxon'
import i18n from '../i18n'

// Export settings
export const SETTINGS_FOR_EXPORT =  {
  // Table settings
  fileName: `data-analytique-${DateTime.local().toISODate()}`,
  workSheets: [
    {
      sheetName: 'example',
      startingRowNumber: 1,
      gapBetweenTwoTables: 1,
      tableSettings: {
        data: {
          headerDefinition: [
            {
              name: i18n.t('user'),
              key: 'user',
              width: 15
            },
            {
              name: i18n.t('date'),
              key: 'date',
              width: 15
            },
            {
              name: i18n.t('imputationDate'),
              key: 'imputationDate',
              width: 15
            },
            {
              name: i18n.t('format'),
              key: 'format',
              width: 15
            },
            {
              name: i18n.t('accountingUnit'),
              key: 'accountingUnit',
              width: 12
            },
            {
              name: i18n.t('siteId'),
              key: 'siteId',
              width: 10
            },
            {
              name: i18n.t('resultCenterId'),
              key: 'resultCenterId',
              width: 12
            },
            {
              name: i18n.t('family'),
              key: 'family',
              width: 15
            },
            {
              name: i18n.t('amount'),
              key: 'amount',
              width: 15
            },
            {
              name: i18n.t('label'),
              key: 'label',
              width: 75
            },
            {
              name: i18n.t('entryType'),
              key: 'entryType',
              width: 30
            },
          ]
        }
      }
    }
  ]
}  