<template>
  <div class="grid-entries" :style="gridColumns">
    <div v-for="header in headers" :key="`header#${header}`" class="header cell">{{ $t(header) }}</div>
    <template v-for="(entry, i) in _entries">
      <template v-for="key in data">
        <div :key="`${key}#${i}`" :class="{ cell: true, right: key === 'amount' }">
          {{ entry[key] }}
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  const data = ['user', 'date', 'imputationDate', 'format', 'accountingUnit', 'siteId', 'resultCenterId', 'family', 'amount', 'label', 'entryType']
  import { formatDateRest } from '@/utils/utils'

  export default {
    name: 'GridEntries',
    props: {
      entries: {
        type: Array,
        default: () => []
      },
      headers: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        data
      }
    },
    computed: {
      gridColumns() {
        return {
          gridTemplateColumns: `repeat(${this.headers.length}, auto)`
        }
      },
      _entries() {
        return this.entries.map(entry => ({
          accountingUnit: entry.accountingUnit,
          date: formatDateRest(entry.date),
          imputationDate: entry.imputationDate,
          format: entry.format,
          family: entry.family,
          label: entry.label,
          resultCenterId: entry.resultCenterId,
          siteId: entry.siteId,
          user: entry.user,
          amount: entry.amount > 0 ? '+' + entry.amount + ' €' : entry.amount + ' €',
          entryType: entry.entryType
        }))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .grid-entries {
    display: grid;
    grid-template-columns: repeat(9, auto);
    .cell {
      padding: 1rem;
      height: 6.4rem;
      @include flex(center, flex-start);
      position: sticky;
      top: 0;
      @media print {
        height: 4rem;
      }
      @media print and (orientation: portrait) {
        padding: 1rem;
      }
      &:not(.header) {
        border-bottom: 1px solid $light-grey;
      }
      &.right {
        padding-right: 2rem;
        justify-content: flex-end;
        font-family: $secondary-font;
      }
    }
    .header {
      @extend %font-bold;
      z-index: 2;
      font-size: 2rem;
      background-color: $background;
      @media print {
        background: none;
      }
    }
  }
</style>
